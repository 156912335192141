/* recent card */
.recentcard {
  overflow: hidden;
}
.recentcard:hover img {
  transform: scale(1.3);
  transition: transform 1s ease;
}
.recentcard img {
  transition: transform 1s ease;
}
.recentcard > .recentoverlay {
  background-color: rgba(0,0,0,0.3);
}
.recentcard:hover .recentoverlay {
  background-color: rgba(0,0,0,0.6);
}
/* .link-holder > a {
  display: none;
} */
.link-holder a {
  display: none;
  transition: display .6s;
}
.link-holder h1 {
  transform: translateY(0px);
  transition: transform .6s ease-in;
}
.recentcard > .recentoverlay > .link-holder {
  z-index: 20;
	 position: absolute;
	 bottom: 0;
	 right: 20px;
   bottom: 20px;
	 font-size: 28px;
	 font-weight: 700;
	 color: #fff;
   transition: all 1s ease;
}
.recentcard:hover .link-holder h1 {
  display: flex;
  animation: moveToTop .6s linear;
}
.recentcard:hover .link-holder a {
  display: flex;
  animation: moveToRight .6s ease-in-out;
}
.customshadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
@keyframes moveToRight {
  0% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes moveToTop {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
  }
}
